import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ADD_MESSAGE_SUCCESS, INBOX_FETCH_SUCCESS, INBOX_FETCH_ERROR, DELETE_MESSAGE, RECEIVE_SOCKET_MESSAGE, RECEIVE_MESSAGE_LOADING, RECEIVE_SOCKET_GROUP_MESSAGE } from "../Reducer/userInboxReducer";
import { POST_FETCH_COMMENT_ADDON, ADD_POST_FETCH_SUCCESS } from "../Reducer/postReducer";
import { MESSAGE_FETCH_ECHO } from '../Reducer/messageReducer';
import NewMessage from '../assets/audio/NewMessage.mp3';
import NotificationSound from '../assets/audio/notification.mp3';
import NewPostSound from '../assets/audio/NewPost.mp3';
import IncomingCallSound from '../assets/audio/IncomingCall.mp3';
import Agent from '../Network/Agent';
import { toast } from 'react-toastify';
import PopupNewPost from '../Props/PopupNewPost';
import CallPopup from '../Props/CallPopup';
import API from "../Network/API";
import { ADD_FPOST_FETCH_SUCCESS, FPOST_FETCH_COMMENT_ADDON } from "../Reducer/friendProfileReducer";
import { SINGLE_POST_FETCH_COMMENT_ADDON } from "../Reducer/singlePostReducer";
import { ACTIVE_Notify_FETCH_START, ADD_Notify_SUCCESS } from "../Reducer/ActiveNotifyReducer";
import { ACTIVE_MEETING_FETCH_START, ADD_MEETING_START_SUCCESS } from "../Reducer/MeetingStartReducer";


function Realtime() {

    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [showIncomingCall, setShowIncomingCall] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [callerAvatar, setCallerAvatar] = useState('');
    const [callData, setCallData] = useState('');
    const [callerName, setCallerName] = useState('');
    const [callId, setCallId] = useState('');
    const [profileId, setProfileId] = useState('');
    const [pId, setPid] = useState(0);
    // Agent.Notification.latest(dispatch) //will remove when there will be noification reducer
    let location = useLocation();

    const [playing, setPlaying] = useState(false);
    const audioRef = useRef(new Audio(IncomingCallSound));
    const navigate = useNavigate();
    const myArr = JSON.parse(localStorage.getItem('user_data'));

    const selector = useSelector((state) => state.settingsReducer);
    const settingsData = selector?.data;

    const idd = location.pathname.split('/');

    const playIncomingCall = () => {
        setPlaying(true);
        audioRef.current.play();
    };

    const pauseIncomingCall = () => {
        setPlaying(false);
        audioRef.current.pause();
    };

    const playSound = () => {
        const audio = new Audio(NewMessage);
        audio.addEventListener('canplaythrough', (event) => {
            audio.play();
        });
    };

    const playNewPostSound = () => {
        const audio = new Audio(NewPostSound);
        audio.addEventListener('canplaythrough', (event) => {
            audio.play();
        });
    };

    const playSoundNotification = () => {
        const audio = new Audio(NotificationSound);
        audio.addEventListener('canplaythrough', (event) => {
            audio.play();
        });
    };


    useEffect(() => {
        setTimeout(() => {
            setShowPopup(false);
        }, 10000);
    }, [profileId]);

    useEffect(() => {
        setTimeout(() => {
            setShowIncomingCall(false);
        }, 30000);
    }, [callId]);

    const chatReducer = useSelector((state) => state.ChatListId)

    useEffect(() => {
        setPid(chatReducer.chat_id)
        localStorage.setItem("user_id",chatReducer.chat_id);
    }, [chatReducer.chat_id]);
  
    useEffect(() => {

        setTimeout(() => {
            const user = JSON.parse(localStorage.getItem("user_data"));

            window.Echo.private(`App.User.${user['id']}`)
                .subscribed(() => {
                    // console.log('subscribed to post comments' + user['id']);
                })
                .listen('PostCommentEvent', (e) => {
                    // console.log(e);
                    dispatch(POST_FETCH_COMMENT_ADDON(e.data));
                    dispatch(FPOST_FETCH_COMMENT_ADDON(e.data));
                    dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(e.data));
                }).listen('PostEvent', (e) => {
                    // console.log(e)
                    setProfilePhoto(e.data.avatar);
                    setShowPopup(true);
                    setProfileId(e.data.id);
                    playNewPostSound();
                    dispatch(ADD_POST_FETCH_SUCCESS(e.data));
                    dispatch(ADD_FPOST_FETCH_SUCCESS(e.data));
                }).listen('IncomingCall', (e) => {
                    setCallerAvatar(e.avatar);
                    setCallId(e.data.id);
                    setCallerName(e.caller);
                    setCallData(e.data);
                    setShowIncomingCall(true);
                    playIncomingCall();
                }).listen('CallAccept', (e) => {
                    // console.log(e.data);
                    navigate(`/meeting/${e.data.room_id}`);
                    // window.open(`/meeting/${e.data.room_id}`, '_blank', 'noreferrer');
                })
                .listen('MeetingStartEvent', (e) => {
                    dispatch(ACTIVE_MEETING_FETCH_START()); 
                    dispatch(ADD_MEETING_START_SUCCESS(e.data)); 
                })  
                .notification((data) => {
                    if(settingsData.allowNotificationSound == true){
                        playSoundNotification();
                    }
                     Agent.Notification.latest(dispatch);
                     dispatch(ACTIVE_Notify_FETCH_START());
                     dispatch(ADD_Notify_SUCCESS(data));

                }).error((error) => {
                    // console.log("echo:error", error);
                });

            // App.User channel end

            window.Echo.private(`App.Chat.${user['id']}`)
                .subscribed(() => {
                })
                .listen('MessageSent', (e) => {
                    if(settingsData.allowMessagingSound == true){
                        playSound();
                    }
                    // console.log("fg="+localStorage.getItem("user_id"))
                    const tt = e.conversations;
                    const payload = {
                        last_message_id: tt.id,
                        name: tt.sender.name,
                        image: tt.sender.avatar,
                        to_id: tt.from_id,
                        is_group: tt.is_group,
                        message: tt.message,
                        url_details: tt.url_details,
                        seen_at: tt.seen_at,
                        last_messages: null,
                        is_online: tt.sender.is_online,
                        unread_count: 1,
                        created_at: tt.created_at,
                    }

                    dispatch(MESSAGE_FETCH_ECHO(payload))
                    if(tt.is_group==0){
                        // console.log(localStorage.getItem("user_id"))
                        if (tt.from_id == localStorage.getItem("user_id") && tt.to_id == myArr.id) {
                            dispatch(RECEIVE_SOCKET_MESSAGE(e.conversations))
                        }
                    }
                    
                }).listen('GroupMessageEvent', (e) => {
                    playSound();
                    const tt = e.conversations;
                    // console.log(tt)
                    const payload = {
                        last_message_id: tt.id,
                        name: tt.receiver.name,
                        image: tt.receiver.avatar,
                        to_id: tt.to_id,
                        is_group: 1,
                        message: tt.message,
                        url_details: tt.url_details,
                        seen_at: tt.seen_at,
                        last_messages: null,
                        is_online: tt.sender.is_online,
                        unread_count: 1,
                        created_at: tt.created_at,
                    }

                    dispatch(MESSAGE_FETCH_ECHO(payload))

                    dispatch(RECEIVE_SOCKET_GROUP_MESSAGE(e.conversations))



                }).error((error) => {
                    // console.log("echo:error", error);
                }); //chat channel end
            //timeout end
        }, 5000);

    }, []);

    function callHangup() {
        setShowIncomingCall(false);
        pauseIncomingCall();
        API.put('update_calling/' + callId + '?status=4', {}).then((response) => {
            console.log('Call Hangup Success');
        }).catch((error) => {
            console.log(error);
        });
    }

    function callAccept() {
        setShowIncomingCall(false);
        pauseIncomingCall();
        API.put('update_calling/' + callId + '?status=2', {}).then((response) => {
            console.log('Call Accepted Success');
            window.open(`/meeting/${callData.room_id}`, '_blank', 'noreferrer');
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            {showPopup &&
                location.pathname === "/" &&
                <PopupNewPost image={profilePhoto} />
            }

            {showIncomingCall &&
                <CallPopup onAccept={() => callAccept()} onClose={() => callHangup()} image={callerAvatar} name={callerName} />
            }

        </>
    );

}

export default Realtime;