import axios from 'axios';

export default axios.create({
        baseURL: `https://b.connectsweb.com/api`,
    // baseURL: `https://devb.connectsweb.com/api`,
    //    baseURL: `http://localhost/connects/public/api`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+localStorage.getItem("connect_token")??""
    }
});